import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { Input, DateTimeRangePicker, Button, DataTable, Pager } from 'tyb'
import moment from 'moment'
import { useSelector } from '@/components/hooks'
import DynamicDataSelect from '@/components/DynamicDataSelect.js'
import $http from '@/utils/http'

const StatusMap = {
  SUBMITTED: '已提交',
  SHOP_APPROVING: '门店审核中',
  SHOP_APPROVED: '门店已审核',
  SHOP_REJECTED: '门店已驳回',
  SFA_APPROVING: 'SFA审核中',
  SFA_APPROVED: 'SFA已审核',
  SFA_REJECTED: 'SFA已驳回',
  COMPLETED: '订单已完成'
}

const StatusList = Object.entries(StatusMap).map(([key, value]) => ({
  text: value,
  value: key
}))

function ShopOrderDetail(props) {
  const { code } = props
  const brandSelecList = useSelector(state => state.brand.brandSelecList || [])
  const [list, setList] = useState([])
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)

  const [searchParams, setSearchParams] = useState({
    page: 0,
    size: 20,
    brandCode: [],
    status: ['COMPLETED']
  })
  const [searchInputs, setSearchInputs] = useState({
    startTime: '',
    endTime: '',
    dealerCode: '',
    phone: ''
  })

  const getList = useCallback(() => {
    if (!code) return

    const { brandCode, status, ...params } = searchParams
    if (brandCode.length) {
      params.brandCode = brandCode.join()
    }
    if (status.length) {
      params.status = status.join()
    }

    setLoading(true)
    $http({
      url: '/channel-openapi/portal/shops/order/v1',
      params: {
        ...params,
        shopCode: code
      }
    })
      .then(res => {
        setList(res.content || [])
        setTotal(res.total)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [searchParams, code])
  useEffect(getList, [getList])

  const brandSelections = useMemo(() => {
    return brandSelecList
      .filter(item => item.code)
      .map(item => ({
        text: item.text,
        value: item.code
      }))
  }, [brandSelecList])
  const columns = useMemo(
    () => [
      {
        key: 'createdTime',
        dataIndex: 'createdTime',
        title: '订单创建时间',
        render: value => <div>{value && moment(value).format('YYYY-MM-DD HH:mm:ss')}</div>
      },
      {
        key: 'orderNo',
        dataIndex: 'orderNo',
        title: '进货单号'
      },
      {
        key: 'brandName',
        dataIndex: 'brandName',
        title: '销售品牌',
        headerRender: col => (
          <DataTable.FilterableColumnHeader
            list={brandSelections}
            value={searchParams.brandCode}
            onFilter={e => {
              setSearchParams(v => ({
                ...v,
                page: 0,
                brandCode: e.filter(it => it)
              }))
            }}>
            {col.title}
          </DataTable.FilterableColumnHeader>
        )
      },
      {
        key: 'product',
        dataIndex: 'product',
        title: '产品名称',
        render: value => {
          const content = value?.length ? value.join(', ') : ''
          return (
            <div className="-line-clamp-2" title={content}>
              {content}
            </div>
          )
        }
      },
      {
        key: 'dealerName',
        dataIndex: 'dealerName',
        title: '经销商',
        render: value => {
          const content = value || ''
          return (
            <div className="-line-clamp-2" title={content}>
              {content}
            </div>
          )
        }
      },
      {
        key: 'distributorName',
        dataIndex: 'distributorName',
        title: '供货商',
        render: value => {
          const content = value || ''
          return (
            <div className="-line-clamp-2" title={content}>
              {content}
            </div>
          )
        }
      },
      {
        key: 'salesman',
        dataIndex: 'salesman',
        title: '业务员'
      },
      {
        key: '整箱数量',
        dataIndex: '整箱数量',
        title: '整箱数量',
        render: (_, record) => <div>{record.levelNums?.[3]}</div>
      },
      {
        key: '单品数量',
        dataIndex: '单品数量',
        title: '单品数量',
        render: (_, record) => {
          if (typeof record.levelNums === 'object') {
            return <div>{(record.levelNums[2] || 0) + (record.levelNums[1] || 0) || ''}</div>
          }
        }
      },
      {
        key: 'phone',
        dataIndex: 'phone',
        title: '收货手机号'
      },
      {
        key: 'status',
        dataIndex: 'status',
        title: '订单状态',
        headerRender: column => (
          <DataTable.FilterableColumnHeader
            list={StatusList}
            value={searchParams.status}
            onFilter={e => {
              setSearchParams(v => ({
                ...v,
                page: 0,
                status: e.filter(it => it)
              }))
            }}>
            {column.title}
          </DataTable.FilterableColumnHeader>
        ),
        render: value => <div>{StatusMap[value]}</div>
      }
    ],
    [brandSelections, searchParams]
  )

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
        <div className="mr-10">
          <DateTimeRangePicker
            placeholder="请选择创建时间"
            value={{
              from: searchInputs.startTime ? moment(+searchInputs.startTime) : null,
              to: searchInputs.endTime ? moment(+searchInputs.endTime) : null
            }}
            onChange={({ from, to }) => {
              setSearchInputs(v => ({
                ...v,
                startTime: moment(from).valueOf(),
                endTime: moment(to).valueOf()
              }))
            }}
          />
        </div>
        <div className="mr-10">
          <DynamicDataSelect
            style={{ width: '200px' }}
            type="dealer"
            placeholder="经销商"
            value={searchInputs.dealerCode}
            onChange={e => {
              setSearchInputs(v => ({
                ...v,
                dealerCode: e
              }))
            }}
          />
        </div>
        {/* <div className="mr-10">
          <Input placeholder="请输入产品名称/单号" style={{ width: '160px' }} />
        </div> */}
        <div className="mr-10">
          <Input
            placeholder="请输入收货手机号"
            style={{ width: '160px' }}
            value={searchInputs.phone}
            onChange={e => {
              setSearchInputs({
                ...searchInputs,
                phone: e.target.value
              })
            }}
          />
        </div>
        <div>
          <Button
            className="mr-10"
            onClick={() => {
              setSearchParams({
                ...searchParams,
                ...searchInputs,
                page: 0
              })
            }}>
            查询
          </Button>
          <Button
            className="weak-button"
            onClick={() => {
              setSearchInputs({ startTime: '', endTime: '', dealerCode: '', phone: '' })
            }}>
            清空
          </Button>
        </div>
      </div>

      <div>
        <DataTable columns={columns} data={list} loading={loading} rowKey="id" />
      </div>

      <div className="page-padding20">
        <Pager
          total={total}
          current={+searchParams.page}
          perPageCountList={[20]}
          perPageCount={+searchParams.size}
          onPageChange={value => {
            setSearchParams(v => ({
              ...v,
              page: value
            }))
          }}
        />
      </div>
    </div>
  )
}

export default ShopOrderDetail
