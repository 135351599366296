import React, { useState, useMemo, useCallback, forwardRef, useImperativeHandle, useEffect } from 'react'
import { NewSelect } from 'ucode-components'
import AddIcon from '@/icons/AddIcon'
import '../index.scss'
import { notification } from 'tyb'
import { MultipleSelect } from 'ucode-components'
import { useSelector, useGetMainData } from '@/components/hooks'
import DynamicDataSelect from '@/components/DynamicDataSelect'

/**
 * @typedef {object} Field
 * @prop {string} id
 * @prop {'DISTRIBUTOR'|'DEALER'} type
 * @prop {string[]} dealer
 * @prop {string[]} SALESMAN
 * @prop {string[]} BRAND
 * @prop {string[]} PRODUCT
 * @prop {string[]} SALES_CHANNEL
 * @prop {string[]} AREA
 */

const groupBy = (arr, fn) =>
  arr.map(typeof fn === 'function' ? fn : val => val[fn]).reduce((acc, val, i) => {
    acc[val] = (acc[val] || []).concat(arr[i])
    return acc
  }, {})

function DistributorSetting(props, ref) {
  const { initialFields, visible = false, disabled = false } = props

  const { salesman, brands, sales_channels, areas } = useSelector(state => ({
    salesman: state.datadealers.serviceStaffIdsList || [],
    brands: state.brand.brandSelecList || [],
    sales_channels: state.channel.channelSelection || [],
    areas: state.precinct.precinctSelection || []
  }))

  /** @type {[Field[]], any} */
  const [fields, setFields] = useState([])

  const selectedDealers = useMemo(() => {
    return [...new Set(fields.map(it => it.dealer).flat())]
  }, [fields])
  const selectedProducts = useMemo(() => {
    return [...new Set(fields.map(it => it.PRODUCT).flat())]
  }, [fields])
  const dealerSelection = useGetMainData({
    type: 'DEALER',
    valueName: 'code',
    keys: selectedDealers
  })
  const productSelections = useGetMainData({
    type: 'PRODUCT',
    valueName: 'code',
    keys: selectedProducts
  })

  const getRelationName = useCallback(
    (key, value) => {
      switch (key) {
        case 'DEALER':
        case 'DISTRIBUTOR':
          return dealerSelection.find(item => item.code === value)?.name
        case 'SALESMAN':
          return salesman.find(item => item.code === value)?.name
        case 'BRAND':
          return brands.find(item => item.code === value)?.name
        case 'PRODUCT':
          return productSelections.find(item => item.value === value)?.text
        case 'SALES_CHANNEL':
          return sales_channels.find(item => item.code === value)?.name
        case 'AREA':
          return areas.find(item => item.code === value)?.name
      }
    },
    [dealerSelection, salesman, brands, productSelections, sales_channels, areas]
  )

  const onSave = useCallback(() => {
    const list = fields.map(item => {
      const { id, type, dealer, ...others } = item
      if (!type || !dealer.length) {
        notification.error('请选择类型和经销商')
        throw new Error('请选择类型和经销商')
      } else {
        return {
          ...others,
          [type]: dealer
        }
      }
    })

    const datas = []
    for (let [index, item] of list.entries()) {
      for (let key of Object.keys(item)) {
        if (typeof item[key] === 'object' && item[key].length) {
          for (let child of item[key]) {
            // if (['DISTRIBUTOR', 'DEALER'].includes(key)) {
            //   datas.push({
            //     relationType: key,
            //     relationCode: child.value,
            //     relationName: child.label,
            //     rows: index
            //   })
            // } else {
            datas.push({
              relationType: key,
              relationCode: child,
              relationName: getRelationName(key, child),
              rows: index
            })
            // }
          }
        }
      }
    }

    return datas
  }, [fields, getRelationName])

  const setInitialFields = useCallback(
    /** @param {{relationCode:number, relationType:string, rows:number}[]} list */
    function(list) {
      const group = groupBy(list, 'rows')

      /** @type {Field[]} */
      const arr = Object.keys(group).map(key => {
        const obj = {
          id: `${Date.now()}${Math.random()}`
        }

        ;['SALESMAN', 'BRAND', 'PRODUCT', 'SALES_CHANNEL', 'AREA'].forEach(prop => {
          obj[prop] = group[key]
            .filter(item => item.relationType === prop)
            .map(item => item.relationCode)
        })

        if (group[key].filter(item => item.relationType === 'DISTRIBUTOR').length) {
          obj.type = 'DISTRIBUTOR'
          obj.dealer = group[key]
            .filter(item => item.relationType === 'DISTRIBUTOR')
            .map(item => item.relationCode)
        } else if (group[key].filter(item => item.relationType === 'DEALER').length) {
          obj.type = 'DEALER'
          obj.dealer = group[key]
            .filter(item => item.relationType === 'DEALER')
            .map(item => item.relationCode)
        }

        return obj
      })
      setFields(arr)
    },
    []
  )

  useEffect(() => {
    if (initialFields.length) {
      setInitialFields(initialFields)
    }
  }, [initialFields, setInitialFields])

  useImperativeHandle(
    ref,
    () => ({
      onSave,
      setInitialFields
    }),
    [onSave, setInitialFields]
  )

  const onFieldChange = useCallback(({ key, value, label, index }) => {
    setFields(list =>
      list.map((target, targetIndex) => {
        if (targetIndex === index) {
          return {
            ...target,
            [key]: value
          }
        } else {
          return target
        }
      })
    )
  }, [])

  const columns = useMemo(
    () => [
      {
        key: 'type',
        dataIndex: 'type',
        title: '类型',
        width: '13%',
        render: (value, record, index) => (
          <div>
            <NewSelect
              style={{ width: '100%' }}
              list={[
                {
                  text: '配送商',
                  value: 'DISTRIBUTOR'
                },
                {
                  text: '所属经销商',
                  value: 'DEALER'
                }
              ]}
              value={value}
              onChange={e => {
                onFieldChange({ key: 'type', value: e, index })
              }}
              disabled={disabled}
            />
          </div>
        )
      },
      {
        key: 'dealer',
        dataIndex: 'dealer',
        title: '经销商',
        width: '13%',
        render(value, record, index) {
          return (
            <div>
              <DynamicDataSelect
                type="dealer"
                valueName="code"
                style={{ width: '100%' }}
                value={value}
                onChange={e => {
                  onFieldChange({
                    key: 'dealer',
                    value: e,
                    index
                  })
                }}
                multiple
                disabled={disabled}
              />
            </div>
          )
        }
      },
      {
        key: 'SALESMAN',
        dataIndex: 'SALESMAN',
        title: '服务人员',
        width: '13%',
        render(value, record, index) {
          return (
            <div>
              <MultipleSelect
                style={{ width: '100%' }}
                list={salesman
                  .filter(it => it.code)
                  .map(it => ({
                    value: it.code,
                    text: it.name
                  }))}
                value={value}
                onChange={e => {
                  onFieldChange({ key: 'SALESMAN', value: e, index })
                }}
                disabled={disabled}
              />
            </div>
          )
        }
      },
      {
        key: 'BRAND',
        dataIndex: 'BRAND',
        title: '品牌',
        width: '13%',
        render(value, record, index) {
          return (
            <div>
              <NewSelect
                style={{ width: '100%' }}
                list={brands
                  .filter(it => it.code)
                  .map(it => ({
                    value: it.code,
                    text: it.name
                  }))}
                value={value && value[0]}
                onChange={e => {
                  onFieldChange({ key: 'BRAND', value: [e], index })
                }}
                disabled={disabled}
              />
            </div>
          )
        }
      },
      {
        key: 'PRODUCT',
        dataIndex: 'PRODUCT',
        title: '产品',
        width: '13%',
        render(value, record, index) {
          return (
            <div>
              <DynamicDataSelect
                type="product"
                valueName="code"
                style={{ width: '100%' }}
                value={value}
                onChange={e => {
                  onFieldChange({ key: 'PRODUCT', value: e, index })
                }}
                multiple
                disabled={disabled}
              />
            </div>
          )
        }
      },
      {
        key: 'SALES_CHANNEL',
        dataIndex: 'SALES_CHANNEL',
        title: '渠道',
        width: '13%',
        render(value, record, index) {
          return (
            <div>
              <MultipleSelect
                style={{ width: '100%' }}
                list={sales_channels
                  .filter(it => it.code)
                  .map(item => ({
                    value: item.code,
                    text: item.name
                  }))}
                value={value}
                onChange={e => {
                  onFieldChange({ key: 'SALES_CHANNEL', value: e, index })
                }}
                disabled={disabled}
              />
            </div>
          )
        }
      },
      {
        key: 'AREA',
        dataIndex: 'AREA',
        title: '区域',
        width: '13%',
        render(value, record, index) {
          return (
            <div>
              <MultipleSelect
                style={{ width: '100%' }}
                list={areas
                  .filter(it => it.code)
                  .map(item => ({
                    value: item.code,
                    text: item.name
                  }))}
                value={value}
                onChange={e => {
                  onFieldChange({ key: 'AREA', value: e, index })
                }}
                disabled={disabled}
              />
            </div>
          )
        }
      },
      {
        key: 'option',
        title: '操作',
        render: (_, record, index) => (
          <div>
            <button
              style={{ backgroundColor: 'transparent', color: '#1469FB' }}
              onClick={() => {
                setFields(list => list.filter((_, i) => i !== index))
              }}
              disabled={disabled}>
              移除
            </button>
          </div>
        )
      }
    ],
    [onFieldChange, salesman, brands, sales_channels, areas, disabled]
  )

  if (!visible) {
    return <></>
  }

  return (
    <div>
      <div className="DistributorSetting-table-header">
        {columns.map(column => (
          <div key={column.key} style={{ width: column.width }}>
            {column.title}
          </div>
        ))}
      </div>

      <div className="DistributorSetting-table-body">
        {fields.map((row, rowIndex) => (
          <div key={row.id} className="DistributorSetting-table-row">
            {columns.map(column => (
              <div key={column.key} style={{ width: column.width }}>
                {column.render
                  ? column.render(row[column.dataIndex], row, rowIndex)
                  : row[column.dataIndex] || ''}
              </div>
            ))}
          </div>
        ))}
      </div>

      <div className="DistributorSetting-addcolumn">
        <AddIcon
          style={{ cursor: 'pointer' }}
          onClick={() => {
            if (disabled) {
              return
            }
            setFields(list => [
              ...list,
              {
                id: `${Date.now()}${Math.random()}`,
                type: '',
                dealer: [],
                SALESMAN: [],
                BRAND: [],
                PRODUCT: [],
                SALES_CHANNEL: [],
                AREA: []
              }
            ])
          }}
        />
      </div>
    </div>
  )
}

export default forwardRef(DistributorSetting)
