import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { DateTimeRangePicker, Button, DataTable, Pager } from 'tyb'
import moment from 'moment'
import { useSelector } from '@/components/hooks'
import DynamicDataSelect from '@/components/DynamicDataSelect.js'
import $http from '@/utils/http'

function ShopNews(props) {
  const { code } = props
  const brandSelecList = useSelector(state => state.brand.brandSelecList || [])
  const [list, setList] = useState([])
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)

  const [searchParams, setSearchParams] = useState({
    page: 0,
    size: 20,
    brandIds: []
  })
  const [searchInputs, setSearchInputs] = useState({
    dateRange: [],
    franchiserId: '',
    supplyFranchiserId: ''
  })

  const getList = useCallback(() => {
    if (!code) return

    const { dateRange = [], brandIds, ...params } = searchParams
    if (dateRange[0] && dateRange[1]) {
      params.purchaseTimeStart = moment(dateRange[0]).valueOf()
      params.purchaseTimeEnd = moment(dateRange[1]).valueOf()
    }
    if (brandIds.length) {
      params.brandIds = brandIds.join()
    }

    setLoading(true)
    $http({
      url: '/tm/portal/terminal-purchase-record/v1',
      params: {
        ...params,
        terminalCode: code
      }
    })
      .then(res => {
        setList(res.content)
        setTotal(res.totalElements)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [searchParams, code])
  useEffect(() => {
    getList()
  }, [getList])

  const columns = useMemo(
    () => [
      {
        key: 'franchiserName',
        dataIndex: 'franchiserName',
        title: '经销商'
      },
      {
        key: 'supplyFranchiserName',
        dataIndex: 'supplyFranchiserName',
        title: '供货商'
      },
      {
        key: 'brandName',
        dataIndex: 'brandName',
        title: '销售品牌',
        headerRender: col => (
          <DataTable.FilterableColumnHeader
            list={brandSelecList}
            value={searchParams.brandIds}
            onFilter={e => {
              setSearchParams(v => ({
                ...v,
                page: 0,
                brandIds: e.filter(it => it)
              }))
            }}>
            {col.title}
          </DataTable.FilterableColumnHeader>
        )
      },
      {
        key: 'boxCount',
        dataIndex: 'boxCount',
        title: '进整箱'
      },
      {
        key: 'packageCount',
        dataIndex: 'packageCount',
        title: '进单品',
        render: (_, record) => <div>{record.packageCount + record.bottleCount}</div>
      },
      {
        key: 'openBottleCount',
        dataIndex: 'openBottleCount',
        title: '开瓶量'
      },
      {
        key: 'purchaseTime',
        dataIndex: 'purchaseTime',
        title: '最近进货时间',
        render: value => <div>{moment(value).format('YYYY-MM-DD HH:mm:ss')}</div>
      }
      // {
      //   key: 'visitTime',
      //   dataIndex: 'visitTime',
      //   title: '最近拜访时间',
      //   render: value => <div>{moment(value).format('YYYY-MM-DD HH:mm:ss')}</div>
      // }
    ],
    [brandSelecList, searchParams]
  )

  const [statistics, setStatistics] = useState({
    bcBottleCount: '', // 累计消费者开瓶
    bbBoxCount: '', // 累计进货箱
    bbPackageCount: '', // 累计进货盒
    bbBottleCount: '' // 累计进货瓶
  })
  const [orderCount, setOrderCount] = useState('')
  useEffect(() => {
    if (!code) return

    $http({ url: `/tm/portal/terminal-purchase-record/v1/brand-count?code=${code}` }).then(res => {
      setStatistics({
        bcBottleCount: res.bcBottleCount || 0,
        bbBoxCount: res.bbBoxCount || 0,
        bbPackageCount: res.bbPackageCount || 0,
        bbBottleCount: res.bbBottleCount || 0
      })
    })

    $http({
      url: '/channel-openapi/portal/shops/order/v1',
      params: {
        page: 0,
        size: 1,
        shopCode: code
      }
    }).then(res => {
      setOrderCount(res.total)
    })
  }, [code])

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
        <div style={{ paddingRight: '60px', borderRight: 'solid 1px #DADADA' }}>
          <span
            style={{
              fontSize: '16px',
              fontWeight: 'bold',
              marginRight: '25px',
              verticalAlign: 'middle'
            }}>
            累计订单数量
          </span>
          <span style={{ fontSize: '30px', verticalAlign: 'middle' }}>{orderCount}</span>
        </div>
        <div
          style={{
            paddingRight: '60px',
            paddingLeft: '20px',
            borderRight: 'solid 1px #DADADA',
            display: 'flex',
            alignItems: 'center'
          }}>
          <span
            style={{
              fontSize: '16px',
              fontWeight: 'bold',
              marginRight: '20px'
            }}>
            累计进货量
          </span>
          <div style={{ textAlign: 'center', marginRight: '20px' }}>
            <div style={{ fontSize: '30px' }}>{statistics.bbBoxCount}</div>
            <div>整箱</div>
          </div>
          <div style={{ textAlign: 'center' }}>
            <div style={{ fontSize: '30px' }}>
              {statistics.bbPackageCount + statistics.bbBottleCount}
            </div>
            <div>单品</div>
          </div>
        </div>
        <div style={{ paddingLeft: '20px' }}>
          <span
            style={{
              fontSize: '16px',
              fontWeight: 'bold',
              marginRight: '25px',
              verticalAlign: 'middle'
            }}>
            累计开瓶量（个）
          </span>
          <span style={{ fontSize: '30px', verticalAlign: 'middle' }}>{statistics.bcBottleCount}</span>
        </div>
      </div>

      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
        <div className="mr-10">
          <DateTimeRangePicker
            isUntilNow={false}
            placeholder="最近进货时间范围"
            value={{
              from: searchInputs.dateRange[0],
              to: searchInputs.dateRange[1]
            }}
            onChange={({ fromFormat, toFormat }) => {
              setSearchInputs(v => ({
                ...v,
                dateRange: [fromFormat, toFormat]
              }))
            }}
          />
        </div>
        <div className="mr-10">
          <DynamicDataSelect
            style={{ width: '200px' }}
            type="dealer"
            placeholder="经销商"
            value={searchInputs.franchiserId}
            onChange={e => {
              setSearchInputs(v => ({
                ...v,
                franchiserId: e
              }))
            }}
          />
        </div>
        <div className="mr-10">
          <DynamicDataSelect
            style={{ width: '200px' }}
            type="dealer"
            placeholder="供货商"
            value={searchInputs.supplyFranchiserId}
            onChange={e => {
              setSearchInputs(v => ({
                ...v,
                supplyFranchiserId: e
              }))
            }}
          />
        </div>
        <div>
          <Button
            className="mr-10"
            onClick={() => {
              setSearchParams({
                ...searchParams,
                ...searchInputs,
                page: 0
              })
            }}>
            查询
          </Button>
          <Button
            className="weak-button"
            onClick={() => {
              setSearchInputs({
                dateRange: [],
                franchiserId: '',
                supplyFranchiserId: ''
              })
            }}>
            清空
          </Button>
        </div>
      </div>

      <div>
        <DataTable columns={columns} data={list} loading={loading} rowKey="id" />
      </div>

      <div className="page-padding20">
        <Pager
          total={total}
          current={+searchParams.page}
          perPageCountList={[20]}
          perPageCount={+searchParams.size}
          onPageChange={value => {
            setSearchParams(v => ({
              ...v,
              page: value
            }))
          }}
        />
      </div>
    </div>
  )
}

export default ShopNews
