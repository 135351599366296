import React, { createRef } from 'react';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { Input, Select, Textarea, FormGroupList, StaticFormGroup, Button, ListSelector, Dialog, notification, TabList } from 'tyb';
import { MultipleSelect } from 'ucode-components';
import { FormGroupField } from 'tyb';
import { reduxForm, FieldArray } from 'redux-form';
import { formatDate } from 'ucode-utils';
const { getZone } = iceStarkData.store.get('commonAction')?.zone;
const { createTerminal, terminalInfo } = iceStarkData.store.get('commonAction')?.terminalManagement;
const { fieldAssignSelection } = iceStarkData.store.get('commonAction')?.fieldAssign;
const { loadDealerSelection, LoadServiceStaffIds } = iceStarkData.store.get('commonAction')?.datadealers;
const { departmentSelections } = iceStarkData.store.get('commonAction')?.departments;
import { NewSelect } from 'ucode-components';
import CustomFields from '../../components/customFields/customFields';
import './index.scss';
const { loadList: loadProductSelect } = iceStarkData.store.get('commonAction')?.products;
const { channelSelection: loadChannelSelection } = iceStarkData.store.get('commonAction')?.channel;
const { precinctSelection } = iceStarkData.store.get('commonAction')?.precinct;
import DistributorSetting from './components/DistributorSetting'
const { loadBrandSelections } = iceStarkData.store.get('commonAction')?.brand
import ShopOrderDetail from './components/ShopOrderDetail.js';
import ShopLevel from './components/ShopLevel.js';
import ShopNews from './components/ShopNews.js';
import ShopStoreInfo from './components/ShopStoreInfo.js';
import ShopCredit from './components/ShopCredit.js';
const { setNavBar } = iceStarkData.store.get('commonAction')?.ui

/**
 * @param {Record<string, any>[]} arr
 */
function disabledAreas(arr) {
    if (arr && arr.length) {
      return arr.map(it => ({
        ...it,
        disabled: true,
        children: disabledAreas(it.children)
      }))
    }
    return []
}

// 新增/编辑 字段
@connect(
    state => ({
        form: state.form.EditTerminalForm,
        zoneList: state.zone.zoneList,
        departmentSelectionsList: state.departments.departmentSelectionsList,
        fieldAssignSelectionList: state.fieldAssign.fieldAssignSelection || [],
        dealerSelectionList: state.datadealers.dealerSelection || [],
        serviceStaffIdsList: state.datadealers.serviceStaffIdsList || [],
        fieldLoading: state.fieldAssign.loading,
        loadDealerSelectionloading: state.datadealers.loadDealerSelectionloading,
        precinctSelectionList: state.precinct.precinctSelection,
        productSelection: state.products.selector,
        channelSelection: state.channel.channelSelection,
    }),
    {
        createTerminal: createTerminal.REQUEST,
        terminalInfo: terminalInfo.REQUEST,
        getZone: getZone.REQUEST,
        departmentSelections: departmentSelections.REQUEST, // 部门
        fieldAssignSelection: fieldAssignSelection.REQUEST,// 自定义
        loadDealerSelection: loadDealerSelection.REQUEST, // 经销商
        LoadServiceStaffIds: LoadServiceStaffIds.REQUEST,//服务人员
        loadProductSelect: loadProductSelect.REQUEST,
        loadChannelSelection: loadChannelSelection.REQUEST,
        precinctSelection: precinctSelection.REQUEST,
        loadBrandSelections: loadBrandSelections.REQUEST,
        setNavBar
     }
)

class EditTerminal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: [],
            showSaleArea: false,
            cityData: [],
            oData: [],
            newZoneListThreeTree: [],
            currentTab: 0,
            dealerSelection: [],
            isEdit: false,
            isEditMore: false,
            initialFields: [],
            parsed: {}
        };

    }

    componentDidMount() {
        const { terminalInfo, getZone, departmentSelections, fieldAssignSelection, loadDealerSelection, LoadServiceStaffIds, initialize, location } = this.props;
        const parsed = queryString.parse(location.search) || {};

        if (parsed.id) {
            this.props.setNavBar('编辑门店')
        } else {
            this.setState({ isEdit: true })
        }

        this.setState({ parsed });
        // getZone();
        departmentSelections(); //部门
        LoadServiceStaffIds({ status: 'ENABLE' });

        this.props.loadChannelSelection({ status: 'ENABLE' });
        this.props.precinctSelection({ status: 'ENABLE' });
        this.props.loadBrandSelections({ status: 'ENABLE' })

        // // 配送商/经销商
        // 配送商/经销商
        fieldAssignSelection({ // dictDataType: 6 TERMINAL_SHOP
            data: { dictDataType: 'TERMINAL_SHOP' }, callback: (filed) => {
                // console.log(filed, 'filed')
                // let b = filed.map(item => { return { ...item, rate: item.dictType == 'TIME' ? formatDate(new Date().getTime()) : '' } });
                // if (parsed.id) {
                //     terminalInfo({
                //         terminalId: parsed.id, callback: (res) => {
                //             console.log(res, 'retminal')
                //             if (res.jsonData && res.jsonData.length) {
                //                 let jsonData = JSON.parse(res.jsonData);
                //                 b = filed.map(item => { return { ...item, rate: item.dictType == 'TIME' ? formatDate(new Date().getTime()) : '', ...(jsonData.filter(val => val.id == item.id)[0] || {}) } });
                //             }

                //             res.departmentIds = res.departmentIds ? res.departmentIds.split(',') : [];

                //             res.dealerIds = res.dealerIds ? res.departmentIds.split(',') : [];

                //             initialize({ ...res, jsonData: b });
                //         }
                //     })
                // } else {
                //     this.props.change('jsonData', b);
                // }
            }
        })

        getZone({
            callback: (res) => {
                if (this.state.newZoneListThreeTree.length === 0) {
                    let tree = [];
                    res.forEach(item => {
                        if (item.level === 1) {
                            let sheng = JSON.parse(JSON.stringify(item));
                            sheng.children = [];
                            tree.push(sheng);
                        } else if (item.level === 2) {
                            tree.forEach(sheng => {
                                if (sheng.code === item.parentCode) {
                                    let shi = JSON.parse(JSON.stringify(item));
                                    shi.children = [];
                                    sheng.children.push(shi)
                                }
                            })
                        } else if (item.level === 3) {
                            tree.forEach(sheng => {
                                // 香港澳门没有level2
                                if (item.parentCode === sheng.code && sheng.children.length === 0) {
                                    let temp = { ...sheng };
                                    temp.code = +`${sheng.code}`;
                                    temp.id = +`${sheng.id}_0`;
                                    temp.level = 2;
                                    temp.value = +`${sheng.value}_0`;
                                    let shi = JSON.parse(JSON.stringify(temp));
                                    if (!shi.children) {
                                        shi.children = [];
                                    }

                                    sheng.children.push(shi);

                                }
                                sheng.children.forEach(shi => {
                                    if (item.parentCode === shi.code) {
                                        let qu = JSON.parse(JSON.stringify(item));
                                        shi.children.push(qu);
                                    }
                                })
                            })
                        }
                    })
                    // 有一些市下面没有区 台湾省没有市
                    tree.forEach(sheng => {
                        if (sheng.children.length === 0) {
                            sheng.children = [{ ...sheng }]
                        }
                        sheng.children.forEach(shi => {
                            if (shi.children.length === 0) {
                                let temp = JSON.parse(JSON.stringify(shi));
                                delete temp.children
                                shi.children = [{ ...temp }]
                            }
                        })
                    })
                    this.setState({
                        newZoneListThreeTree: tree
                    })
                }
            }
        });

        this.distributorSettingRef = createRef()
    }

    componentDidUpdate(preProps) {
        const { fieldLoading, fieldAssignSelectionList: filed = [], loadDealerSelectionloading, terminalInfo, initialize } = this.props;

        if ((fieldLoading != preProps.fieldLoading || loadDealerSelectionloading != preProps.loadDealerSelectionloading) && !fieldLoading && !loadDealerSelectionloading) {
            this.getAndSetInfo()
        }
    }

    getAndSetInfo() {
        const { fieldAssignSelectionList: filed = [], terminalInfo, initialize } = this.props
        const { parsed } = this.state;
        let b = filed.map(item => { return { ...item, rate: item.dictType == 'TIME' ? formatDate(new Date().getTime()) : '' } });
        if (parsed.id) {
            terminalInfo({
                terminalId: parsed.id, callback: (res) => {
                    this.setState({ initialFields: res?.shopSalesRelationList || [] })

                    if (res.customFields && res.customFields.length) {
                        let customFields = JSON.parse(res.customFields);
                        b = filed.map(item => { return { ...item, rate: item.dictType == 'TIME' ? formatDate(new Date().getTime()) : '', ...(customFields.filter(val => val.id == item.id)[0] || {}) } });
                    }

                    if (res.salesAreaCode) {
                        let arr = res.salesAreaCode.split(',');
                        let arr2 = arr.map(v => Number(v));
                        res.salesAreaCode = arr2;
                        this.setState({ cityData: arr2 });
                    }

                    res.dealerIds = res.dealerIds ? res.dealerIds.split(',') : [];
                    res.departmentIds = res.departmentIds ? res.departmentIds.split(',') : [];

                    initialize({ ...res, customFields: b });
                }
            })
        } else {
            this.props.change('customFields', b);
        }
    }

    // 提交
    handleSubmit = (data) => {
        let formdata = {};
        Object.keys(data).map(v => {
            if (data[v]) {
                formdata[v] = data[v];
            }
        })

        if (formdata.customFields) {
            formdata.customFields = JSON.stringify(formdata.customFields.map(item => { return { id: item.id, rate: item.rate, rate1: item.rate1, rate2: item.rate2, rate3: item.rate3, code: item.code, id: item.id, value: item.value, value1: item.value1, value2: item.value2, value3: item.value3 } }));
        }
        if (formdata.departmentIds) {
            formdata.departmentIds = formdata.departmentIds.join(',');
        }
        // if (formdata.dealerId) {
        //     formdata.dealerId = formdata.dealerId.value;
        // }

        if (formdata.dealerIds) {
            formdata.dealerIds = formdata.dealerIds.join(',');
        }

        if (formdata.salesAreaCode) {
            formdata.salesAreaCode = formdata.salesAreaCode.join(',');
        }

        // if (formdata.serviceStaffIds) {
        //     formdata.serviceStaffIds = formdata.serviceStaffIds.join(',');
        // }
        // return

        const shopSalesRelationList = this.distributorSettingRef.current?.onSave()
        if (shopSalesRelationList) {
            formdata.shopSalesRelationList = shopSalesRelationList
        }

        this.props.createTerminal({
            data: formdata, callback: (res) => {
                this.setState({ isEdit: false, isEditMore: false })
                if (this.state.parsed.id) {
                    this.getAndSetInfo()
                } else {
                    // 新创建
                    this.props.history.replace(`/databasicData/datashop/edit?id=${res.id}`)
                    this.props.setNavBar('编辑门店')
                    this.setState({ parsed: { ...this.state.parsed, id: res.id } }, () => {
                        this.getAndSetInfo()
                    })
                }
            }
        })
    }

    // 销售区域弹窗
    SaleAreaDialog = () => (
        <Dialog
            className={'commonModal saleArea'}
            title="添加销售区域"
            visible={this.state.showSaleArea}
            okText="确认"
            cancelText="取消"
            onOk={() => {
                const { cityData } = this.state;
                if (cityData.length) {
                    this.props.change('salesAreaCode', cityData);
                    this.setState({ showSaleArea: false });
                } else {
                    notification.error('选择销售区域');
                }
            }}
            onCancel={() => { this.setState({ showSaleArea: false, cityData: this.state.oData }) }}
            shouldCloseOnOverlayClick={false}
            hasFooter={this.state.isEdit}>
            <ListSelector
                value={this.state.cityData}
                list={this.state.isEdit ? this.state.newZoneListThreeTree : disabledAreas(this.state.newZoneListThreeTree)}
                prepareItemTextFn={(item) => {
                    return <span>{item.text}</span>
                }}
                selectedItemTextFn={(item) => {
                    return <span>{item.parent.parent ? (item.parent.parent.text + '-') : ''}{item.parent.text}-{item.text}</span>
                }}
                onChange={value => {
                    this.setState({ cityData: value })
                }}
            ></ListSelector>
        </Dialog>
    )

    render() {
        const { isEdit, isEditMore } = this.state
        const { zoneList, handleSubmit, form = {}, departmentSelectionsList, dealerSelectionList, serviceStaffIdsList } = this.props;
        const { values = {} } = form;
        return (
            <>
            <div className="terminal-box">

                {this.SaleAreaDialog()}

                <div className="flex-box">
                    <FormGroupList>
                        <FormGroupField
                            name="code"
                            label="终端编码"
                            placeholder="请输入终端编码"
                            component={Input}
                            disabled={!isEdit}
                        >
                        </FormGroupField>
                        <FormGroupField
                            name="contact"
                            label="联系人"
                            placeholder="请输入联系人姓名"
                            component={Input}
                            required={true}
                            disabled={!isEdit}
                        >
                        </FormGroupField>
                        {/* <FormGroupField
                            name="mobile"
                            label="手机号"
                            placeholder="请输入手机号"
                            component={Input}
                            required={true}
                        >
                        </FormGroupField> */}
                        <StaticFormGroup
                            label="所在地"
                            className="select-address-box"
                            required={true}
                        >
                            <FormGroupList>
                                <FormGroupField
                                    name="province"
                                    placeholder="请选择省"
                                    component={NewSelect}
                                    required={true}
                                    style={{ width: 105 }}
                                    list={zoneList.filter(item => item.level == 1)}
                                    onChange={() => {
                                        this.props.change('city', '');
                                        this.props.change('district', '');
                                    }}
                                    disabled={!isEdit}
                                >
                                </FormGroupField>
                                <FormGroupField
                                    name="city"
                                    placeholder="请选择市"
                                    component={NewSelect}
                                    style={{ width: 105 }}
                                    required={true}
                                    list={zoneList.filter(item => item.level == 2 && item.parentCode == values.province)}
                                    onChange={() => {
                                        this.props.change('district', '');
                                    }}
                                    disabled={!isEdit}
                                >
                                </FormGroupField>
                                <FormGroupField
                                    name="district"
                                    placeholder="请选择区"
                                    style={{ width: 105 }}
                                    component={NewSelect}
                                    required={true}
                                    list={zoneList.filter(item => item.level == 3 && item.parentCode == values.city)}
                                    disabled={!isEdit}
                                >
                                </FormGroupField>
                            </FormGroupList>
                        </StaticFormGroup>
                        <FormGroupField
                            name="address"
                            label="详细地址"
                            placeholder="请输入详细地址"
                            component={Textarea}
                            required={true}
                            disabled={!isEdit}
                        >
                        </FormGroupField>

                        <StaticFormGroup
                            name="salesAreaCode"
                            label="销售区域"
                        >
                            <span className="blue" onClick={() => { this.setState({ showSaleArea: true, oData: JSON.parse(JSON.stringify(this.state.cityData)) }) }}>{isEdit ? '添加' : '查看'}</span>
                        </StaticFormGroup>
                    </FormGroupList>

                    <FormGroupList>
                        <FormGroupField
                            name="name"
                            label="终端名称"
                            placeholder="请输入终端名称"
                            component={Input}
                            required={true}
                            disabled={!isEdit}
                        >
                        </FormGroupField>

                        <FormGroupField
                            name="mobile"
                            label="手机号"
                            placeholder="请输入手机号"
                            component={Input}
                            required={true}
                            disabled={!isEdit}
                        >
                        </FormGroupField>

                        <FormGroupField
                            name="department"
                            label="管理部门"
                            placeholder="请选择管理部门"
                            component={NewSelect}
                            style={{ width: '100%' }}
                            list={departmentSelectionsList}
                            disabled={!isEdit}
                        />

                        {/* <StaticFormGroup
                            label="终端所属"
                        >
                            <NewSelect
                                needText
                                placeholder="请选择"
                                list={dealerSelectionList.map(item => { return { value: item.id, text: item.name } })}
                                value={values.dealerId}
                                onChange={(val) => {
                                    this.props.change('dealerId', val);
                                }}
                            />
                        </StaticFormGroup> */}

                        {/* <StaticFormGroup
                            label="配送商"
                        >
                            <MultipleSelect
                                placeholder="请选择"
                                list={dealerSelectionList.map(item => { return { value: item.id, text: item.name } })}
                                value={values.dealerIds}
                                onChange={(val) => {
                                    this.props.change('dealerIds', val);
                                }}
                            />
                        </StaticFormGroup> */}
                        {/* <StaticFormGroup
                            label="服务人员"
                        >
                            <MultipleSelect
                                placeholder="请选择"
                                list={serviceStaffIdsList.map(item => { return { value: item.id, text: item.account + '-' + item.name } })}
                                value={values.serviceStaffIds}
                                onChange={(val) => {
                                    this.props.change('serviceStaffIds', val);
                                }}
                            />
                        </StaticFormGroup> */}
                        <FormGroupField
                            name="notes"
                            label="备注信息"
                            placeholder="请输入备注信息"
                            component={Textarea}
                            disabled={!isEdit}
                        >
                        </FormGroupField>

                        {values.authenticity && <FormGroupField
                            name="authenticity"
                            label="真实级别"
                            component={Input}
                            disabled
                        >
                        </FormGroupField>}
                    </FormGroupList>
                </div>

                {/* <div className="form-edit-border" style={{ marginBottom: 20 }}>
                    <CustomFields name='jsonData'></CustomFields>
                </div> */}

                <div className="form-edit-border" style={{ marginTop: 20 }}>
                    <Button
                        className="tc-15-btn weak"
                        style={{ marginLeft: 0 }}
                        onClick={() => {
                            this.props.history.push("/datashop");
                        }}
                    >
                        取消
                    </Button>

                    {
                        this.state.isEdit ? (
                            <Button style={{ marginLeft: 10 }} onClick={handleSubmit(this.handleSubmit)}>
                                保存
                            </Button>
                        ) : (
                            <Button className="tc-15-btn weak" onClick={() => {
                                if (isEditMore && this.state.currentTab === 0) {
                                    notification.error('请先保存自定义字段')
                                } else if (isEditMore && this.state.currentTab === 1) {
                                    notification.error('请先保存经销商设置')
                                } else {
                                    this.setState({ isEdit: true })
                                }
                            }}>编辑</Button>
                        )
                    }
                </div>
            </div>

            <div className="terminal-box">
                <TabList list={[
                        { text: '自定义字段', value: 0 },
                        { text: '经销商设置', value: 1 },
                        ...(values.code
                            ? [
                                { text: '订单详情', value: 2 },
                                { text: '门店等级', value: 3 },
                                { text: '门店动态', value: 4 },
                                { text: '店员信息', value: 5 },
                                { text: '门店积分', value: 6 }
                            ] 
                            : [])
                    ]}
                    value={this.state.currentTab}
                    onChange={e => {
                        this.setState({ currentTab: e, isEditMore: false })
                    }}
                />

                {this.state.currentTab === 0 && (
                    <CustomFields name='customFields' disabled={!isEditMore} handleChange={e => {
                        const { index, url } = e
                        let arr = values?.customFields || []
                        arr = arr.map((it, i) => {
                            if (i === +index) {
                                return {
                                    ...it,
                                    value: url || ''
                                }
                            } else {
                                return it
                            }
                        })

                        this.props.change('customFields', arr)
                    }}></CustomFields>
                )}

                {this.state.currentTab === 1 && <DistributorSetting visible={true} disabled={!isEditMore} ref={this.distributorSettingRef} initialFields={this.state.initialFields} />}

                <div className="MoreInfoTables">
                    {this.state.currentTab === 2 && <ShopOrderDetail code={values.code} />}
                    {this.state.currentTab === 3 && <ShopLevel appId={values.eseId} openId={values.code} />}
                    {this.state.currentTab === 4 && <ShopNews code={values.code} />}
                    {this.state.currentTab === 5 && <ShopStoreInfo code={values.code} />}
                    {this.state.currentTab === 6 && <ShopCredit appId={process.env.env === 'prod' ? 'wxe624a1cddc0ce950' : 'wx5beb96ee823dd86a'} openId={values.code} />}
                </div>

                {
                    (this.state.currentTab === 0 || this.state.currentTab === 1) &&
                    <div style={{ marginTop: 20 }}>
                        {isEditMore ? <Button onClick={handleSubmit(this.handleSubmit)}>保存</Button> : <Button className="weak-button" onClick={() => {
                            if (isEdit) {
                                notification.error('请先保存门店基本信息')
                            } else {
                                this.setState({ isEditMore: true })
                            }
                        }}>编辑</Button>}
                    </div>
                }
            </div>
            </>
        )
    }
}

const validate = (values, props) => {
    const errors = {};
    const regName = /^([a-z]|[A-Z]|[\·]|[\u4e00-\u9fa5]){1,20}$/;
    const regMobile = /^[1][2,3,4,5,6,7,8,9][0-9]{9}$/;
    if (values.code && values.code.trim().length > 100) {
        errors.code = '长度不超过100个字符';
    }

    if (!values.name || !values.name.trim()) {
        errors.name = '请输入门店名称';
    } else if (values.name.trim().length > 100) {
        errors.name = '长度不超过100个字符';
    }

    if (!values.contact || !values.contact.trim()) {
        errors.contact = '请输入联系人';
    } else if (values.contact && values.contact.trim().length > 20) {
        errors.contact = '请输入字母，汉字，长度不超过20个字符';
    }

    if (!values.mobile || values.mobile && values.mobile.trim().length == 0) {
        errors.mobile = "请输入手机号";
    }
    if (values.mobile && !regMobile.test(values.mobile)) {
        errors.mobile = "请输入正确的手机号";
    }

    if (!values.address || !values.address.trim()) {
        errors.address = '请输入详细地址';
    } else if (values.address.trim().length > 500) {
        errors.address = '长度不超过500个字符';
    }

    if (!values.province) {
        errors.province = '请选择省';
    }

    if (!values.city) {
        errors.city = '请选择市';
    }

    // if (!values.district) {
    //     errors.district = '请选择区';
    // }

    if (values.description && values.description.trim().length > 1000) {
        errors.description = '最多输入1000个字符';
    }
    return errors;
}

export default reduxForm({
    form: 'EditTerminalForm',
    initialValues: {
        // dealerId: []
        departmentIds: []
    },
    validate
})(EditTerminal)
