import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react'
import {
  Input,
  Button,
  DataTable,
  Dialog,
  Textarea,
  FormGroupList,
  StaticFormGroup,
  DatePicker,
  Radio,
  PageLoading
} from 'tyb'
import { NewSelect } from 'ucode-components'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import { useDispatch, useSelector, useStyles } from '@/components/hooks'
import { FormGroupField } from 'tyb'
import { reduxForm } from 'redux-form'
import qs from 'query-string'
import $http from '@/utils/http'

function LevelSelect(props) {
  const { value, onChange, currentLevel, list = [] } = props

  return (
    <div style={{ display: 'flex', alignItems: 'center', fontSize: '12px' }}>
      <div style={{ flexShrink: 0 }}>LV{currentLevel}</div>
      <div style={{ flexShrink: 0 }} className="ml-10 mr-10">
        调整至
      </div>
      <div style={{ flexGrow: 1 }}>
        <NewSelect style={{ width: '100%' }} value={value} onChange={onChange} list={list} />
      </div>
    </div>
  )
}

function GrowthInput(props) {
  const { value, onChange, currentNumber } = props

  useEffect(() => {
    if (!value?.type) {
      onChange({
        type: '1',
        value: ''
      })
    }
  }, [value?.type])

  return (
    <div style={{ fontSize: '12px' }}>
      <div style={{ marginBottom: '10px', lineHeight: '30px' }}>{currentNumber}</div>

      <div style={{ marginBottom: '15px' }}>
        <Radio
          checked={value.type === '1'}
          onChange={() => {
            onChange({
              value: '',
              type: '1'
            })
          }}>
          <div style={{ display: 'inline-flex', alignItems: 'center' }}>
            <div className="mr-10" style={{ width: '3em' }}>
              调整至
            </div>
            <div style={{ flexGrow: 1 }}>
              <Input
                style={{ width: '100%' }}
                disabled={value.type !== '1'}
                value={value.type === '1' ? value.value : ''}
                onChange={e => {
                  if (/^\d+$/.test(e.target.value) || e.target.value === '') {
                    onChange({
                      ...value,
                      value: e.target.value
                    })
                  }
                }}
              />
            </div>
          </div>
        </Radio>
      </div>
      <div style={{ marginBottom: '15px' }}>
        <Radio
          checked={value.type === '2'}
          onChange={() => {
            onChange({
              value: '',
              type: '2'
            })
          }}>
          <div style={{ display: 'inline-flex', alignItems: 'center' }}>
            <div className="mr-10" style={{ width: '3em' }}>
              增加
            </div>
            <div style={{ flexGrow: 1 }}>
              <Input
                style={{ width: '100%' }}
                disabled={value.type !== '2'}
                value={value.type === '2' ? value.value : ''}
                onChange={e => {
                  if (/^[1-9]\d*$/.test(e.target.value) || e.target.value === '') {
                    onChange({
                      ...value,
                      value: e.target.value
                    })
                  }
                }}
              />
            </div>
          </div>
        </Radio>
      </div>
      <div style={{ marginBottom: '15px' }}>
        <Radio
          checked={value.type === '3'}
          onChange={() => {
            onChange({
              value: '',
              type: '3'
            })
          }}>
          <div style={{ display: 'inline-flex', alignItems: 'center' }}>
            <div className="mr-10" style={{ width: '3em' }}>
              减少
            </div>
            <div style={{ flexGrow: 1 }}>
              <Input
                style={{ width: '100%' }}
                disabled={value.type !== '3'}
                value={value.type === '3' ? value.value : ''}
                onChange={e => {
                  if (/^[1-9]\d*$/.test(e.target.value) || e.target.value === '') {
                    onChange({
                      ...value,
                      value: e.target.value
                    })
                  }
                }}
              />
            </div>
          </div>
        </Radio>
      </div>
      <div style={{ color: '#BBBBBB', paddingTop: '4px' }}>调整后将重新计算会员等级</div>
    </div>
  )
}

function ShopLevel(props) {
  useStyles(`
    .tc-15-calendar-select-wrap {
      width: 300px;
    }
    .tc-15-calendar-select {
      width: 100%;
    }
    .ShopLevelForm .is-error .icon-valid-flag {
      display: none;
    }
  `)

  const { handleSubmit, history, location, appId, openId, initialize } = props
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [confirming, setConfirming] = useState(false)
  const formData = useSelector(state => state.form.ShopLevelForm?.values || {})

  const getList = useCallback(() => {
    if (!appId || !openId) return
    setLoading(true)
    $http({
      url: '/member-center/portal/manage/v1/memberDetails',
      params: {
        appId,
        openId,
        authType: 'MINI_PROGRAM'
      }
    })
      .then((res = []) => {
        setList(res.filter?.(item => item.systemDTO?.membershipSystemType === 'B_SYSTEM_TYPE'))
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [appId, openId])
  useEffect(getList, [getList])

  const selectedSystem = useMemo(() => {
    return list.find(item => item.systemId === formData.systemId)
  }, [list, formData.systemId])

  const currentLevelSelections = useMemo(() => {
    if (selectedSystem) {
      return selectedSystem.levels.map(value => ({
        text: `LV${value}`,
        value,
        disabled: selectedSystem.level === value
      }))
    } else {
      return []
    }
  }, [selectedSystem])

  const headSystemIdRef = useRef('')
  useEffect(() => {
    if (list.length) {
      headSystemIdRef.current = list[0].systemId
    }
  }, [list])
  useEffect(() => {
    if (visible) {
      initialize({
        systemId: headSystemIdRef.current,
        systemType: '1'
      })
    } else {
      initialize({})
    }
  }, [visible])

  const onSubmit = useCallback(
    values => {
      const { systemId, growth, systemType, level, remark = '', expireTime } = values
      if (systemType === '1') {
        // 调整等级
        setConfirming(true)
        $http({
          url: '/member-center/portal/manage/v1/updateMemberLelve',
          method: 'put',
          data: {
            appId,
            openId,
            systemId,
            level,
            remark,
            currentGrowthValue: selectedSystem.currentGrowthValue,
            currentLevel: selectedSystem.level,
            method: selectedSystem.method,
            expireTime: expireTime ? moment(expireTime).valueOf() : ''
          }
        })
          .then(() => {
            setConfirming(false)
            setVisible(false)
            getList()
          })
          .catch(() => {
            setConfirming(false)
          })
      } else if (systemType === '2') {
        // 调整成长值
        let amount
        if (growth.type === '1') {
          amount = Number(growth.value) - selectedSystem.currentGrowthValue
        } else if (growth.type === '2') {
          amount = Number(growth.value)
        } else if (growth.type === '3') {
          amount = -Number(growth.value)
        }
        setConfirming(true)
        $http({
          url: '/member-center/portal/manage/v1/empiricalValue',
          method: 'put',
          data: {
            appId,
            openId,
            systemId,
            amount,
            currentGrowthValue: selectedSystem.currentGrowthValue,
            method: selectedSystem.method
          }
        })
          .then(() => {
            setConfirming(false)
            setVisible(false)
            getList()
          })
          .catch(() => {
            setConfirming(false)
          })
      }
    },
    [appId, openId, selectedSystem, getList]
  )

  const [interestsVisible, setInterestsVisible] = useState(false)
  const [interestsDialogLoading, setInterestsDialogLoading] = useState(false)
  const [currentInterests, setCurrentInterests] = useState([])
  const openInterests = useCallback(
    ({ systemId, level }) => {
      setInterestsVisible(true)
      setInterestsDialogLoading(true)
      $http({
        url: '/member-center/portal/rights/v1/rights',
        params: {
          eseId: appId,
          systemId,
          level
        }
      })
        .then(res => {
          setCurrentInterests(res?.map(item => item.name) || [])
          setInterestsDialogLoading(false)
        })
        .catch(() => {
          setInterestsDialogLoading(false)
        })
    },
    [appId]
  )

  const columns = useMemo(
    () => [
      {
        key: 'systemName',
        dataIndex: 'systemName',
        title: '等级体系名称'
      },
      {
        key: 'level',
        dataIndex: 'level',
        title: '门店等级'
      },
      {
        key: 'currentGrowthValue',
        dataIndex: 'currentGrowthValue',
        title: '当前成长值'
      },
      {
        key: 'gradeValidity',
        dataIndex: 'gradeValidity',
        title: '截至有效期',
        render: (_, record) => (
          <div>
            {record.gradeValidity === 'FOREVER'
              ? '永久'
              : record.validityDate && moment(record.validityDate).format('YYYY-MM-DD HH:mm:ss')}
          </div>
        )
      },
      {
        key: '当前权益',
        dataIndex: '当前权益',
        title: '当前权益',
        render: (_, record) => (
          <div>
            <button
              className="link-button"
              onClick={() => {
                openInterests({
                  systemId: record.systemId,
                  level: record.level
                })
              }}>
              查看
            </button>
          </div>
        )
      }
    ],
    [openInterests]
  )

  return (
    <div>
      <div className="mb-10">
        <Button
          className="mr-10"
          onClick={() => {
            setVisible(true)
          }}>
          等级调整
        </Button>
        <Button
          className="weak-button"
          onClick={() => {
            history.push(`/databasicData/datashop/growth-record/?appId=${appId}&openId=${openId}`)
          }}>
          成长值记录
        </Button>
      </div>

      <DataTable columns={columns} data={list} loading={loading} rowKey="id" />

      {visible && (
        <Dialog
          style={{ width: '450px', minWidth: '450px' }}
          title="等级调整"
          visible={visible}
          confirming={confirming}
          onOk={handleSubmit(onSubmit)}
          onCancel={() => {
            setVisible(false)
          }}>
          <div className="ShopLevelForm">
            <FormGroupList>
              <FormGroupField
                label="会员体系"
                name="systemId"
                component={NewSelect}
                list={list.map(item => ({
                  text: item.systemName,
                  value: item.systemId
                }))}
                style={{ width: '300px' }}
              />
              <FormGroupField
                label="调整选择"
                name="systemType"
                component={NewSelect}
                list={[
                  {
                    text: '调整等级',
                    value: '1'
                  },
                  {
                    text: '调整成长值',
                    value: '2'
                  }
                ]}
                style={{ width: '300px' }}
              />

              {formData.systemType === '1' && selectedSystem && (
                <>
                  <FormGroupField
                    label="当前等级"
                    name="level"
                    component={LevelSelect}
                    list={currentLevelSelections}
                    currentLevel={selectedSystem.level}
                  />
                  <StaticFormGroup label="当前有效期">
                    {selectedSystem.gradeValidity === 'FOREVER'
                      ? '永久'
                      : selectedSystem.validityDate &&
                      moment(selectedSystem.validityDate).format('YYYY-MM-DD HH:mm:ss')}
                  </StaticFormGroup>
                  {selectedSystem.gradeValidity !== 'FOREVER' && (
                    <FormGroupField
                      label="调整有效期至"
                      name="expireTime"
                      type="datetime"
                      component={DatePicker}
                      range={{ min: new Date() }}>
                      <div style={{ fontSize: '12px', color: '#BBBBBB', paddingTop: '4px' }}>
                        调整后成长值均为当前等级最小值（ 前一等级最大值 ）
                      </div>
                    </FormGroupField>
                  )}
                </>
              )}

              {formData.systemType === '2' && selectedSystem && (
                <FormGroupField
                  label="当前成长值"
                  name="growth"
                  currentNumber={selectedSystem.currentGrowthValue}
                  component={GrowthInput}
                />
              )}

              <FormGroupField
                label="备注"
                name="remark"
                component={Textarea}
                style={{ width: '300px' }}
              />
            </FormGroupList>
          </div>
        </Dialog>
      )}

      {interestsVisible && (
        <Dialog
          title="当前权益"
          visible={interestsVisible}
          hasFooter={false}
          style={{ width: '300px', minWidth: '300px' }}
          onOk={() => {
            setInterestsVisible(false)
          }}
          onCancel={() => {
            setInterestsVisible(false)
          }}>
          {interestsDialogLoading && <PageLoading style={{ padding: '20px 0' }} />}
          {!interestsDialogLoading && (
            <>
              {currentInterests.length > 0 && (
                <div>
                  {currentInterests.map((name, index) => (
                    <div key={index} style={{ marginBottom: '10px', fontSize: '12px' }}>
                      {name}
                    </div>
                  ))}
                </div>
              )}
              {!currentInterests.length && (
                <div style={{ fontSize: '12px', color: '#6e6e6e' }}>当前体系登记暂无权益</div>
              )}
            </>
          )}

          <div style={{ textAlign: 'right', marginTop: '20px' }}>
            <Button
              onClick={() => {
                setInterestsVisible(false)
              }}>
              确定
            </Button>
          </div>
        </Dialog>
      )}
    </div>
  )
}

function validate(values) {
  const err = {}

  if (!values.systemId) {
    err.systemId = '请选择会员体系'
  }

  if (values.systemType === '1') {
    if (!values.level) {
      err.level = '请选择等级'
    }

    if (values.hasOwnProperty('expireTime') && !values.expireTime) {
      err.expireTime = '请选择有效期'
    }
  } else if (values.systemType === '2') {
    const growth = values.growth || {}
    if (!growth.value) {
      err.growth = '请填写成长值'
    }
  }

  if (values.remark?.length > 200) {
    err.remark = '长度不能超过200'
  }

  return err
}

export default reduxForm({
  form: 'ShopLevelForm',
  destroyOnUnmount: false,
  validate
})(withRouter(ShopLevel))
